import * as React from "react";
import Layout from "../components/layout/MainLayout";
import { graphql } from "gatsby";
import { WmkSeo } from "wmk-seo";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import HeroVideo from "../components/ui/HeroVideo";
import { Page } from "../classes/Page";
import { PageFields } from "../fragments/nodePageFields";
import Tiles from "../components/routes/Home/Tiles/BlockTiles";
import { CallToAction } from "../components/ui/CallToAction";
import { RichTextReact } from "wmk-rich-text";
import { options } from "../components/blocks/RichText/richTextOptions";
import ContactFormHome from "../components/routes/Home/ContactFormHome";
import { BlockIconButtonsRowFields } from "../fragments/blockIconButtonsRowFields";
import { IconButtonsRow } from "../classes/IconButtonsRow";
import BlockIconButtonsRow from "../components/blocks/library/BlockIconButtonsRow/BlockIconButtonsRow";
import { Helmet } from "react-helmet";

type HomePageQuery = {
  data: {
    page: PageFields;
    hero: {
      siteTitle: string | null;
      // videoUrl: string | null;
      // video: { file: { url: string } } | null;
      heroVideo: {
        file: { url: string; contentType: string };
      };
      longText: { copy: string } | null;
    };
    solutionsRow: BlockIconButtonsRowFields;
  };
};

const IndexPage = ({ data }: HomePageQuery) => {
  const { hero, solutionsRow } = data;
  const page = new Page(data.page);
  const desc = page.meta;
  const title = page.titleBar ? page.titleBar : page.title;
  const solutionsRowClassed = new IconButtonsRow(solutionsRow);
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Watermark Agency",
    "alternateName": "WMK Agency",
    "url": "https://wmkagency.com/",
    "logo": "https://images.ctfassets.net/l6o0o2yu98mw/5HRjGm49WrGmSkyWRbHhp0/f3df1b0a3eb8bd9f201877e3b65a0bb1/logomark.svg"
  }
  return (
    <Layout>
      <Helmet>
      <script type="application/ld+json">
          {`${JSON.stringify(ldJson)}`}
        </script>
      </Helmet>
      <WmkSeo.Schema
        siteTitle={siteTitle}
        baseUrl={baseUrl}
        graphs={[
          {
            "@type": "FAQPage",
            "@id": baseUrl + "/#homepage",
            url: baseUrl,
            inLanguage: "en-US",
            name: title,
            isPartOf: {
              "@id": baseUrl + "/#website",
            },
            about: {
              "@id": baseUrl + "/#organization",
            },
            description: desc,
          },
        ]}
      />
      <WmkSeo.Meta
        title={title}
        path=""
        slug={"/"}
        description={desc}
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      {hero.heroVideo?.file?.url ? (
        <HeroVideo url={hero.heroVideo.file.url} />
      ) : null}
      <BlockIconButtonsRow block={solutionsRowClassed} currSol={title} />
      <RichTextReact content={page.content} options={options} />
      {/* <ContactFormHome title="Let Us Tell Your Story"/> */}
      <Tiles />
      <CallToAction
        msg1="Let us tell"
        msg2="Your story"
        path="/contact"
        label="Contact Us"
      />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    page: contentfulWmkPages(slug: { eq: "home" }) {
      ...NodePageFields
    }
    hero: contentfulGlobalOptions(slug: { eq: "home-hero-video" }) {
      siteTitle
      heroVideo {
        file {
          url
          contentType
        }
      }
      longText {
        copy: longText
      }
    }
    solutionsRow: contentfulBlockIconButtonsRow(
      title: { eq: "Solutions Page Icon Buttons" }
    ) {
      ...BlockIconButtonsRowFields
    }
  }
`;
